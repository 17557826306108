
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import Factors from '@/store/enums/IREFactorsEnums';

  export default defineComponent({
    name: 'ire-investment-factors-locations-specs-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(
          translate('ireInvestmentFactorsLocationSpecsListing'),
          [translate('ire-investment')]
        );
      });

      await store.dispatch(
        Actions.GET_IRE_FACTORS,
        Factors.LOCATIONSPECSINVESTMENT
      );
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('titleEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('titleAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('sort'),
          key: 'sort',
        },
        {
          name: translate('isActive'),
          key: 'isActive',
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusIreFactorsLocationSpecs = async (
        ireFactorsLocationSpecs
      ) => {
        const deletedMessage = ireFactorsLocationSpecs.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(
            Actions.DELETE_IRE_FACTOR,
            ireFactorsLocationSpecs.id
          );
          await store.dispatch(
            Actions.GET_IRE_FACTORS,
            Factors.LOCATIONSPECSINVESTMENT
          );
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const SuccessMsg = ireFactorsLocationSpecs.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(SuccessMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const editIreFactorsLocationSpecs = (id) => {
        router.push({
          name: 'ire-investment-factors-location-specs-editing',
          params: { id },
        });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_IRE_FACTOR_CURRENT_PAGE, val);
        await store.dispatch(
          Actions.GET_IRE_FACTORS,
          Factors.LOCATIONSPECSINVESTMENT
        );
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.ireFactorsList),
        totalItems: computed(() => store.getters.ireFactorsListCount),
        tableHeader,
        toggleStatusIreFactorsLocationSpecs,
        translate,
        pageChanged,
        goTo,
        editIreFactorsLocationSpecs,
        loading,
        can,
      };
    },
  });
